/* eslint-disable eqeqeq */
import axios from "axios";
import CryptoJS from 'crypto-js';
import {logOutRedirectCall} from '../pages/common/RedirectPathMange'

const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_KEY);
const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV);

const showMessage = (msg) => {
  console.log("Message : ",msg)
}

// REACT_APP_DEV_API_URL
// REACT_APP_API_URL
// REACT_APP_PROD_URL
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_DEV_API_URL,
    headers : { 
        'api-key' : process.env.REACT_APP_API_KEY,
        'accept-language' : 'en',
        'Content-Type' : 'text/plain',
        'is_admin' : '1',
    }
});

// Body Encryption Request
axiosClient.interceptors.request.use(function (request) {
  request.data = bodyEncryption(request.data, true)
    if(localStorage.getItem("Atoken") !== undefined || localStorage.getItem("Atoken") !== null){
      request.headers['token']=bodyEncryption(localStorage.getItem("Atoken"))
    }
    console.log("Final Config",request.data)
  return request;
});
  
axiosClient.interceptors.response.use(
  function (response) {
    response = bodyDecryption(response.data);
    if(response.code==400){
      showMessage(response.data.message)
    }
    return response;
  }, 
  function (error) {
    let res = error.response;
    // console.log("ERROR : ",error);
    // console.log("Decrypt Data else response", res)
    if (res.status == 401) {
      // console.log("----------------- 401 ----------------------")
      logOutRedirectCall()
    }else if(res.status == 400){
      const response = bodyDecryption(res.data);
      return response 
    }else{
      console.error("Looks like there was a problem. Status Code: " + res.status);
      return Promise.reject(error);
    }
    
  }
);   

function bodyEncryption(request, isStringify)
{
  const requestData = (isStringify) ? JSON.stringify(request) : request;
  const encrypted = CryptoJS.AES.encrypt(requestData, key, { iv: iv });
  return encrypted.toString();
}


function bodyDecryption(request)
{
  const decrypted = CryptoJS.AES.decrypt(request, key, { iv: iv });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export {axiosClient};